
import { defineComponent, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { MBDatatable } from "magicbean-saas-common";
import { ApiExceptionHandlings } from "@/core/api";
import addExceptionHandlingsModal from "@/views/mb-order-routing/fulfillment-orders/AddExceptionHandlingsModal.vue";
import { useI18n } from "vue-i18n";
import {
  FilterOption,
  SortOption,
  TableHeader,
} from "@/core/directive/interface/common";
import { Exception } from "@/core/directive/interface/fulfillmentOrder";
import { getExceptionHandlingProcessMap } from "@/core/directive/function/exceptionHandling";
import {
  formatDate,
  formatDateTime,
  setModuleBCN,
} from "@/core/directive/function/common";

export default defineComponent({
  name: "fulfillment-order-exceptions",
  components: { MBDatatable, addExceptionHandlingsModal },
  setup() {
    const { t } = useI18n();

    const route = useRoute();
    const router = useRouter();
    const loading = ref(true);
    const tableData = ref<Array<Exception>>([]);
    const currentPage = ref<number>(1);
    const total = ref<number>(1);
    const pageSize = ref<number>(10);
    const search = ref<string>("");
    const filterOptions = ref<Array<FilterOption>>([
      { field: "fulfillment_order_id", value: route.params.id },
    ]);
    const sortOptions = ref<Array<SortOption>>([
      { field: "created_at", direction: "desc" },
    ]);
    const tableHeader = ref<Array<TableHeader>>([
      {
        name: t("exceptionHandlings.id"),
        key: "id",
        sortable: true,
      },
      {
        name: t("exceptionHandlings.priority"),
        key: "priority",
        sortable: false,
      },
      {
        name: t("exceptionHandlings.summary"),
        key: "title",
        sortable: false,
      },
      {
        name: t("salesOrder.orderNumber"),
        key: "sale_order_number",
        sortable: false,
      },
      {
        name: t("exceptionHandlings.date"),
        key: "created_at",
        sortable: true,
      },
      {
        name: t("exceptionHandlings.status"),
        key: "progress",
        sortable: false,
      },
      {
        name: t("common.action"),
        key: "actions",
        sortable: false,
      },
    ]);

    const statusClass = (status) => {
      return getExceptionHandlingProcessMap(Number(status), t);
    };

    const getCompanyList = (
      page: number,
      pageSize: number,
      search?: string,
      filterOptions?: Array<FilterOption>,
      sortOptions?: Array<SortOption>
    ) => {
      loading.value = true;
      ApiExceptionHandlings.getExceptionsList({
        page: page,
        page_size: pageSize,
        search_value: search,
        filter_group: filterOptions,
        sort_orders: sortOptions,
      })
        .then(({ data }) => {
          loading.value = false;
          if (data.code == 0) {
            tableData.value.splice(
              0,
              tableData.value.length,
              ...data.data.items
            );
            total.value = data.data.total;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const onCurrentPageChange = (page: number) => {
      currentPage.value = page;
      getCompanyList(
        page,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const onRowsPerPageChange = (pagesize: number) => {
      pageSize.value = pagesize;
      getCompanyList(
        currentPage.value,
        pagesize,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const updateList = () => {
      getCompanyList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const onColumnSort = (val) => {
      let sortObj: SortOption = {
        field: val.columnName,
        direction: val.order,
      };
      sortOptions.value.splice(0, sortOptions.value.length, sortObj);
      getCompanyList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    onMounted(() => {
      setModuleBCN(t, route, router);
      getCompanyList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    });

    return {
      t,
      formatDate,
      formatDateTime,
      loading,
      tableHeader,
      tableData,
      total,
      currentPage,
      pageSize,
      search,
      statusClass,
      updateList,
      onCurrentPageChange,
      onRowsPerPageChange,
      onColumnSort,
    };
  },
});
