import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, withCtx as _withCtx, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  class: "card mb-5 mb-xl-10",
  id: "kt_profile_details_view"
}
const _hoisted_2 = { class: "card-header cursor-pointer" }
const _hoisted_3 = { class: "card-title m-0" }
const _hoisted_4 = { class: "fw-bolder m-0" }
const _hoisted_5 = { class: "card-title m-0" }
const _hoisted_6 = {
  type: "button",
  class: "btn btn-primary",
  "data-bs-toggle": "modal",
  "data-bs-target": "#modal_add_exception_handlings"
}
const _hoisted_7 = { class: "svg-icon svg-icon-2" }
const _hoisted_8 = { class: "card-body p-9" }
const _hoisted_9 = {
  key: 0,
  class: "svg-icon svg-icon-3"
}
const _hoisted_10 = {
  key: 1,
  class: "svg-icon svg-icon-3 svg-icon-warning"
}
const _hoisted_11 = { class: "svg-icon svg-icon-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg_icon = _resolveComponent("inline-svg-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_MBDatatable = _resolveComponent("MBDatatable")!
  const _component_addExceptionHandlingsModal = _resolveComponent("addExceptionHandlingsModal")!
  const _directive_auth = _resolveDirective("auth")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.t("fulfillmentOrder.exceptions")), 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _withDirectives((_openBlock(), _createElementBlock("button", _hoisted_6, [
            _createElementVNode("span", _hoisted_7, [
              _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/arrows/arr075.svg" })
            ]),
            _createTextVNode(" " + _toDisplayString(_ctx.t("common.add")), 1)
          ])), [
            [_directive_auth, { auth: ['update'] }]
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_MBDatatable, {
          loading: _ctx.loading,
          "table-data": _ctx.tableData,
          "table-header": _ctx.tableHeader,
          "enable-items-per-page-dropdown": true,
          "current-page": _ctx.currentPage,
          total: _ctx.total,
          "rows-per-page": _ctx.pageSize,
          order: "desc",
          onCurrentChange: _ctx.onCurrentPageChange,
          onItemsPerPageChange: _ctx.onRowsPerPageChange,
          onSort: _ctx.onColumnSort
        }, {
          "cell-id": _withCtx(({ row: item }) => [
            _createVNode(_component_router_link, {
              to: 
              '/risk-and-exception/exception-handling/' +
              item.id +
              '/information'
            ,
              class: "text-gray-800 text-hover-primary mb-1"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(item.id), 1)
              ]),
              _: 2
            }, 1032, ["to"])
          ]),
          "cell-priority": _withCtx(({ row: item }) => [
            (item.level == 1)
              ? (_openBlock(), _createElementBlock("span", _hoisted_9, [
                  _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/arrows/arr081.svg" })
                ]))
              : (item.level == 2)
                ? (_openBlock(), _createElementBlock("span", _hoisted_10, [
                    _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/arrows/arr081.svg" })
                  ]))
                : _createCommentVNode("", true)
          ]),
          "cell-title": _withCtx(({ row: item }) => [
            _createTextVNode(_toDisplayString(item.title), 1)
          ]),
          "cell-sale_order_number": _withCtx(({ row: item }) => [
            _createTextVNode(_toDisplayString(item.sale_order_number), 1)
          ]),
          "cell-created_at": _withCtx(({ row: item }) => [
            _createTextVNode(_toDisplayString(_ctx.formatDateTime(item.created_at)), 1)
          ]),
          "cell-progress": _withCtx(({ row: item }) => [
            _createElementVNode("span", {
              class: _normalizeClass(["badge", _ctx.statusClass(item.progress)?.color])
            }, _toDisplayString(item.__show.progress), 3)
          ]),
          "cell-actions": _withCtx(({ row: item }) => [
            _createVNode(_component_router_link, {
              to: 
              '/risk-and-exception/exception-handling/' +
              item.id +
              '/information'
            ,
              class: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_11, [
                  _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/art/art005.svg" })
                ])
              ]),
              _: 2
            }, 1032, ["to"])
          ]),
          _: 1
        }, 8, ["loading", "table-data", "table-header", "current-page", "total", "rows-per-page", "onCurrentChange", "onItemsPerPageChange", "onSort"])
      ])
    ]),
    _createVNode(_component_addExceptionHandlingsModal, { onUpdateList: _ctx.updateList }, null, 8, ["onUpdateList"])
  ], 64))
}