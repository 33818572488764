
import { defineComponent, ref, onMounted, onBeforeMount, computed } from "vue";
import { useRoute } from "vue-router";
import { hideModal } from "@/core/helpers/dom";
import { ApiBase, ApiExceptionHandlings } from "@/core/api";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import _ from "lodash";
import { TaggingItem } from "@/core/directive/interface/common";
import {
  CommonButtonType,
  CommonFormType,
  CommonHtmlType,
} from "@/core/directive/type/common";

export default defineComponent({
  name: "add-exception-handlings-modal",
  components: {},
  emits: ["update-list"],
  setup(props, { emit }) {
    const { t } = useI18n();

    const route = useRoute();
    const formRef = ref<CommonFormType>(null);
    const loading = ref<boolean>(false);
    const addExceptionHandlingsRef = ref<CommonHtmlType>(null);
    const submitButton = ref<CommonButtonType>(null);

    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const formData = ref({
      fulfillment_order_number: route.params.fulfillment_order_number,
      title: "",
      // customer_service_id: "",
      customer_request: "",
      solution: "",
      category: "",
      cargo_damage_level: "",
      solution_remark: "",
    });
    // watch(
    //   () => props.fulfillmentOrderNumber,
    //   (newVal) => {
    //     formData.value.fulfillment_order_number = newVal;
    //   }
    // );

    const validatePass2 = (rule: any, value: any, callback: any) => {
      //  && formRef.value?.model.category == 2
      if (value === "") {
        callback(new Error(t("common.isRequired")));
      } else {
        callback();
      }
    };

    const rules = ref({
      fulfillment_order_number: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      title: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      // customer_service_id: [
      //   {
      //     required: true,
      //     message: "Customer Service Agent is required",
      //     trigger: "change",
      //   },
      // ],
      // customer_request: [
      //   {
      //     required: true,
      //     message: t("common.isRequired"),
      //     trigger: "change",
      //   },
      // ],
      solution: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      category: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      cargo_damage_level: [
        {
          validator: validatePass2,
          trigger: "change",
        },
      ],
    });

    const options = ref({
      type: [] as TaggingItem[],
      progress: [] as TaggingItem[],
      solution: [] as TaggingItem[],
      customer: [] as TaggingItem[],
      customerLoading: false,
      category: [] as TaggingItem[],
      cargo_damage_level: [] as TaggingItem[],
    });

    const handleCategory = () => {
      formData.value.cargo_damage_level = "";
    };

    const getCargoDamageLevel = computed(() => {
      let cargoDamageLevel: TaggingItem[] = [];
      if (formData.value.category !== "") {
        options.value.cargo_damage_level.map((item: TaggingItem) => {
          if (formData.value.category === "1" && item.value === "10") {
            cargoDamageLevel.push(item);
          } else if (formData.value.category === "2" && item.value === "20") {
            cargoDamageLevel.push(item);
          } else if (
            formData.value.category === "3" &&
            (item.value === "40" || item.value === "30")
          ) {
            cargoDamageLevel.push(item);
          } else if (
            formData.value.category === "4" &&
            (item.value === "50" || item.value === "60" || item.value === "70")
          ) {
            cargoDamageLevel.push(item);
          } else if (
            formData.value.category === "5" &&
            (item.value === "80" || item.value === "90" || item.value === "100")
          ) {
            cargoDamageLevel.push(item);
          } else if (formData.value.category === "6" && item.value === "110") {
            cargoDamageLevel.push(item);
          } else if (
            formData.value.category === "7" &&
            (item.value === "120" || item.value === "130")
          ) {
            cargoDamageLevel.push(item);
          } else if (
            formData.value.category === "8" &&
            (item.value === "140" ||
              item.value === "150" ||
              item.value === "160")
          ) {
            cargoDamageLevel.push(item);
          } else if (
            formData.value.category === "9" &&
            (item.value === "170" ||
              item.value === "180" ||
              item.value === "190" ||
              item.value === "200" ||
              item.value === "210")
          ) {
            cargoDamageLevel.push(item);
          } else if (
            formData.value.category === "10" &&
            (item.value === "220" || item.value === "230")
          ) {
            cargoDamageLevel.push(item);
          }
        });
      }
      return cargoDamageLevel;
    });

    const getOptions = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: [
          "exception_handling_type",
          "exception_handling_progress",
          "exception_handling_solution",
          "monitoring_rules_category",
          "monitoring_rules_cargo_damage_level",
        ],
      });
      if (data.code == 0) {
        options.value.type = data.data.exception_handling_type.items;
        options.value.progress = data.data.exception_handling_progress.items;
        options.value.solution = data.data.exception_handling_solution.items;
        options.value.category = data.data.monitoring_rules_category.items;
        options.value.cargo_damage_level =
          data.data.monitoring_rules_cargo_damage_level.items;
      }
    };

    const getCustomerOptions = async (query: string) => {
      options.value.customerLoading = true;
      const { data } = await ApiBase.getCustomerSourceData({
        search_value: query,
      });
      options.value.customerLoading = false;
      if (data.code == 0) {
        options.value.customer = data.data;
      }
    };

    const debounceCustomerSearch = _.debounce(getCustomerOptions, 1000);

    const searchCustomerItems = (query: string) => {
      debounceCustomerSearch(query);
    };

    const handleDiscard = () => {
      hideModal(addExceptionHandlingsRef.value);
      resetForm();
    };

    const resetForm = () => {
      formRef.value?.resetFields();
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid) {
          if (loading.value) return;
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          ApiExceptionHandlings.addExceptions(formData.value)
            .then(({ data }) => {
              loading.value = false;
              submitButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                showFormSubmitSuccessMsg(() => {
                  resetForm();
                  hideModal(addExceptionHandlingsRef.value);
                  emit("update-list");
                });
              } else {
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const modalShowListener = (modalEl: HTMLElement | null, callback): void => {
      if (!modalEl) {
        return;
      }
      modalEl.addEventListener("show.bs.modal", callback);
    };

    const getFormInfo = () => {
      loading.value = true;
      Promise.all([getOptions()])
        .then(() => {
          loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    onBeforeMount(() => {
      getFormInfo();
    });

    onMounted(() => {
      modalShowListener(addExceptionHandlingsRef.value, () => {
        formData.value.fulfillment_order_number =
          route.params.fulfillment_order_number;
      });
    });

    return {
      t,
      loading,
      formData,
      addExceptionHandlingsRef,
      submitButton,
      rules,
      formRef,
      options,
      searchCustomerItems,
      handleCategory,
      getCargoDamageLevel,
      submit,
      handleDiscard,
      resetForm,
    };
  },
});
